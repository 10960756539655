<template>
    <div id="formprint" :style="(type) ? '' : 'display: block;'">
        <div id="wrap">
            <!-- <v-img :src="require('@/assets/images/KIESC_logo_no_bg.png')" width="90" /> -->
            <h2><div class="text-center font-weight-bold font-16">ບິນຝາກເຄື່ອງ</div></h2>
            <div class="font-10">ເລກທີຕິດຕາມພັດສະດຸ: <strong>{{ data.track_code }}</strong></div>
            <div class="font-10">ວັນທີຝາກ: <strong>{{ $helper.formatFontDate(data.added_date)}}</strong></div>
            <div class="font-10">ເວລາຝາກ: <strong>{{ $helper.formatFontTime(data.added_date) }}</strong></div>
            <div class="font-10">ຜູ້ບັນທຶກ: <strong>{{ data.added_name }}</strong></div>
            <table class="w-100" border="1">
                <tr>
                    <th colspan="2" class="text-center" style="border-right: none; padding-top: 2px;">ຂໍ້ມູນຜູ້ຝາກ</th>
                    <th class="text-center" style="border-left: none;"></th>
                    <th class="text-center" style="border-right: none;"></th>
                    <th colspan="2" class="text-center" style="border-left: none; padding-top: 2px;">ຂໍ້ມູນຜູ້ຮັບ</th>
                </tr>
                <tr>
                    <td colspan="2" style="border-right: none; padding-left: 4px;">
                        <div>{{ data.form_customer }}</div>
                        <div>{{ data.form_customer_name }}</div>
                        <div>{{ data.form_customer_tel }}</div>
                    </td>
                    <th class="text-center" style="border-left: none;"></th>
                    <th class="text-center" style="border-right: none;"></th>
                    <td colspan="2" style="border-left: none; padding-left: 4px;">
                        <div>{{ data.to_customer }}</div>
                        <div>{{ data.to_customer_name }}</div>
                        <div>{{ data.to_customer_tel }}</div>
                    </td>
                </tr>
                <tr>
                    <td colspan="6" style="border-right: none; ">
                        <div class="d-flex justify-content-around">
                            <div class="font-weight-bold">{{ data.form_branch_name }}</div>
                            <div>=></div>
                            <div class="font-weight-bold">{{ data.to_branch_name }}</div>

                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="6" style="border-right: none; padding-left: 4px; padding-right: 4px;">
                        <div class="text-justify text-center font-weight-bold">{{ data.pack_name }}</div>
                    </td>
                </tr>
                <tr>
                    <td colspan="6" style="border-right: none; padding-left: 4px; padding-right: 4px;">
                        <div class="text-justify">
                            ລາຍລະອຽດພັດສະດຸ: <strong>{{ data.pack_remark }}</strong>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="6" style="border-right: none; padding-left: 4px; padding-right: 4px;">
                        <div class="d-flex justify-content-between">
                            <div>ລວມຄ່າບໍລິການທັງໝົດ: </div>
                            <div><strong>{{ $helper.formatNumber(data.pack_price,0) }}</strong></div>
                        </div>
                    </td>
                </tr>
            </table>
            <div class="mt-4 pt-2 text-center" style="border: 1px solid #111; padding: 3px; border-radius: 5px; margin-bottom: 10px;">
                <barcode :value="data.track_code" :options="{ displayValue: false, width: 1.5, height: 60, fontoptions: 'bold', background: 'transparent' }" tag="svg" />
                <div><strong>{{ data.track_code }}</strong></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            data: {}
        },
        data() {
            return {
                type: false,
            }
        },   
        created() {
            console.log(this.data)
        },
        methods: {
            async printPreview(){
                this.type = await true;
                this.$htmlToPaper('formprint',{
                    name: '_blank',
                    specs: [
                        'fullscreen=yes',
                        'titlebar=yes',
                        'scrollbars=yes'
                    ],
                    // specs: [],
                    styles: [
                        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                        'https://unpkg.com/kidlat-css/css/kidlat.css',
                        `css/printPstyle.css`,
                    ],

                    timeout: 1000,
                    autoClose: false,
                    windowTitle: "ພີມໃບຄິດໄລ່ອາກອນຂົນສົ່ງ",
                });
                this.type = await false;
            }
        },
    }
</script>

<style>

</style>